<template>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
    >
        <v-card elevation="0">
            <v-card-actions>
                <v-card-title>{{ formTitle }}</v-card-title>
                <v-spacer></v-spacer>
                <v-btn :to="{ name: 'ActivityTypes' }" color="green" rounded
                >
                {{$t('Cancel')}}
                </v-btn>
                <v-btn
                :disabled="!valid"
                color="primary"
                @click="save"
                rounded
                >
                {{$t('Save')}}
                </v-btn>
            </v-card-actions>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                        <v-text-field
                            v-model="item.name"
                            :label="$t('Name')"
                            :rules="rules.name"
                            required
                        ></v-text-field>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >

                            <v-text-field
                                v-model="item.color"
                                :label="$t('Choose Color')"
                            ></v-text-field>

                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                        <v-textarea
                            v-model="item.description"
                            :label="$t('Description')"
                        ></v-textarea>
                        <v-select
                            v-model="item.selectedUserIds"
                            :items="users"
                            attach
                            chips
                            :label="$t('Select one or multiple Account Managers')"
                            multiple
                            item-text="fullName"
                            item-value="id"
                        ></v-select>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                            <v-color-picker
                                dot-size="25"
                                mode="hexa"
                                swatches-max-height="200"
                                v-model="color"
                                @input="menu2 = false"
                            ></v-color-picker>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script>
export default {

    data() {
        return {
            valid: true,
            item: {},
            id:"",
            loading:false,
            color:'',
            menu2:false,
            rules: {
                name:[
                    value => !!value || this.$t('Required.'),
                    value => (value || '').length <= 100 || this.$t('Max 100 characters'),
                ],
            },
            users: null,
            selectedUsers: null,
        }
    },

    computed: {
        formTitle () {
            return this.id == "" ? this.$t('Add type of Activity') : this.$t('Edit type of Activity')
        },
    },
    watch: {
        color:{
            handler () {
                this.item.color = this.color
            }
        }
    },
    created: function()
    {
        if(this.$route.query.id){
            this.id = this.$route.query.id
            this.getItem();
        }
        this.fetchUsers();
    },

    methods: {
        getItem()
        {
            this.loading = true
            let uri = this.$urlPrefix + '/ActivityType/Get?id=' + this.id;
            this.axios.get(uri).then((response) => {
                this.item = response.data;
                this.color = this.item.color!=null?this.item.color:'#666666';
                this.loading = false
            });
        },
        save () {
            if(this.$refs.form.validate()){
                this.loading = true
                if (this.id != "") {
                    let uri = this.$urlPrefix + '/ActivityType/Update/';
                    this.axios.put(uri, this.item).then((response) => {
                        if(response.status == 204 || response.status == 200){
                                this.loading = false
                                this.$router.push({name: 'ActivityTypes', query: { page: this.$route.query.page }});
                        }
                    });
                } else {
                    let uri = this.$urlPrefix + '/ActivityType/Add';
                    this.axios.post(uri, this.item).then((response) => {
                        if(response.status == 200){
                            this.item = {}
                            this.loading = false
                            this.$router.push({name: 'ActivityTypes', query: { page: this.$route.query.page }});
                        }
                    });
                }
            }
        },
        fetchUsers() {
            let uri = this.$urlPrefix + "/User/CountAll?includeDeleted=false";
            this.axios.get(uri).then((response) => {
                let uri2 =
                this.$urlPrefix +
                "/User/GetList?start=0&count=" +
                response.data +
                "&includeDeleted=false";
                this.axios.get(uri2).then((response) => {
                this.users = response.data.filter(function (user) {
                    return user.role == 1 || user.role == 2;
                });
                });
            });
        },
    }
}
</script>
